

































import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
    props: {
        id: {type: String},
    },
    data() {
        return {
            item: {},
        }
    },
    created() {
        // this.id = this.$route.params.id
        // console.log(this.id)
        this.getData()
    },
    methods: {
        getData() {
            request.get(`/api/tobacco/expert/${this.id}`).then((res: any) => this.item = res)
        }
    }
})
